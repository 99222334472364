// material
import {
  Box,
  Grid,
  Container,
  Typography,
  Card,
  styled,
  CardHeader,
  CardContent,
  Button,
  useTheme
} from '@mui/material';
// components
import Page from '../components/Page';

import { Chart as ChartJS, registerables } from 'chart.js';

import { Bar } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { getByDate, getByState } from 'src/services/TicketServices';
import { countLogs, getAlarms } from 'src/services/AlarmServices';
import { getUsers } from 'src/services/AppUserServices';
import Map from 'src/sections/dashboard/map';
import { useNavigate } from 'react-router-dom';

ChartJS.register(...registerables);
// ----------------------------------------------------------------------

const DashboardBox = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.grey[300],
  padding: theme.spacing(2)
}));

const OutlinedButton = styled((props) => <Button {...props} variant="outlined" />)(({ theme }) => ({
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: 0,
  ':hover': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light
  }
}));

const ContainedButton = styled((props) => <Button {...props} variant="contained" />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.light,
    borderRadius: 0,
    ':hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.text.light
    }
  })
);

const options = {
  indexAxis: 'x',
  maintainAspectRatio: false,
  elements: {
    bar: {
      borderWidth: 0
    },
    line: {
      zIndex: 10000
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom'
    },
    title: {
      display: false
    }
  }
};

export default function DashboardApp() {
  const [alarms, setAlarms] = useState([]);
  const [users, setUsers] = useState([]);
  const [alerts, setAlerts] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getAlarms().then((x) => {
      setAlarms(x.data?.result);
      for (let i = 0; i < x.data?.result.length; i++) {
        const element = x.data?.result[i];
        getUsers(null, null, null, element._id).then((a) =>
          setUsers((prev) => [...prev, ...a.data.result])
        );
      }
    });
    countLogs().then((x) => {
      setAlerts(x.data ?? 0);
    });
  }, []);

  const handleClickAlarm = (alarm) => {
    navigate(`/alarm/edit/${alarm._id}`);
  };

  return (
    <Page title="Inicio">
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Card>
              <CardContent>
                <Grid container p={1} spacing={5}>
                  <Grid item xs={12} md={4}>
                    <DashboardBox>
                      <Typography variant="h6">Usuarios</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {users.length}
                      </Typography>
                    </DashboardBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DashboardBox>
                      <Typography variant="h6">Alarmas</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {alarms.length}
                      </Typography>
                    </DashboardBox>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <DashboardBox>
                      <Typography variant="h6">Alertas</Typography>
                      <Typography variant="h3" sx={{ color: 'primary.main' }}>
                        {alerts}
                      </Typography>
                    </DashboardBox>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={12}>
            <Map data={alarms} onClickAlarm={handleClickAlarm} />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
