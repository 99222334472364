import axios from 'axios';
import { getUserData, setUserData } from 'src/storage/userSessionStorage';

async function loginService(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/auth/login/', cred);
  setUserData(result.data);
  return result.data;
}

async function getUser(token) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${token}`
    }
  });

  const result = await axiosClient.get('/auth');
  return result.data;
}

async function changePassword(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/auth/changePassword', cred);
  const userData = getUserData();
  setUserData({ ...userData, user: result.data });
  return result.data;
}

async function changePasswordByCode(cred) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  const result = await axiosClient.post('/auth/changePasswordByCode', cred);
  const userData = getUserData();
  setUserData({ ...userData, user: result.data });
  return result.data;
}

export { loginService, getUser, changePassword, changePasswordByCode };
