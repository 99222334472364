import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getUsers(page, length, search) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/user?page=${page ?? ''}&length=${length ?? ''}&search=${search ?? ''}`
  );
}

async function getUser(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(`/user/${id}`);
}

async function createUser(data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.post('/user', { ...data });
  return result.data;
}

async function updateUser(id, data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.put(`/user/${id}`, { ...data });
  return result.data;
}

async function deleteUser(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.delete(`/user/${id}`);
  return result.data;
}

async function createUserCode(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.post(`/user/createCode/${id}`);
}

async function getUserByCode(code) {
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return await axiosClient.post(`/user/getByCode/${code}`);
}

export { getUsers, createUser, updateUser, deleteUser, getUser, getUserByCode, createUserCode };
