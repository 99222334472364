import {
  Container,
  Button,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  Typography,
  Card,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import Page from 'src/components/Page';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteAlarm, getAlarm, getAlarms, getAlarmsLogs } from 'src/services/AlarmServices';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { AlarmListHead, AlarmListToolbar } from 'src/sections/alarm';
import { toast } from 'react-toastify';
import LogListHead from 'src/sections/logs/LogListHead';
import LogListToolbar from 'src/sections/logs/LogListToolbar';

const TABLE_HEAD = [
  { id: 'date', label: 'Fecha', alignRight: false },
  { id: 'user', label: 'Usuario', alignRight: false },
  { id: 'location', label: 'Lugar', alignRight: false },
  { id: 'silence', label: 'Silenciosa', alignRight: false }
];

function AlarmLogs() {
  const [logs, setLogs] = useState([]);
  const [currentAlarm, setCurrentAlarm] = useState(null);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const { alarm } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getAlarm(alarm).then((r) => {
      setCurrentAlarm(r.data);
    });
  }, []);

  useEffect(() => {
    setLoading(true);
    getAlarmsLogs(page, 10, search, alarm)
      .then((x) => {
        setLogs(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (page > 1) {
        setPage(1);
      } else if (search != null) {
        setLoading(true);
        getAlarmsLogs(page, 10, search, alarm)
          .then((x) => {
            setLogs(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [search]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const emptyRows = page > 1 ? Math.max(0, 10 - logs.length) : 0;

  const isNotFound = logs.length === 0;

  return (
    <Page title="Alarmas">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Logs de la alarma {currentAlarm?.code}
          </Typography>
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            startIcon={<Iconify icon="eva:chevron-left-outline" />}
          >
            Regresar
          </Button>
        </Stack>
        <Card>
          <LogListToolbar filter={search} onFilter={handleSearch} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <LogListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {logs.map((row) => {
                    const { _id, user, lat, lng, date, silence } = row;
                    return (
                      <TableRow key={_id} tabIndex={-1} role="checkbox">
                        <TableCell align="left">{new Date(date).toLocaleDateString()}</TableCell>
                        <TableCell align="left">
                          {user?.name} {user?.lastName}
                        </TableCell>
                        <TableCell align="left">
                          <a
                            href={`https://maps.google.com/?q=${lat},${lng}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {lat}:{lng}
                          </a>
                        </TableCell>
                        <TableCell align="left">{silence ? 'Si' : 'No'}</TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={4} />
                    </TableRow>
                  )}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                        {loading ? (
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              Loading...
                            </Typography>
                          </Paper>
                        ) : (
                          <Paper>
                            <Typography gutterBottom align="center" variant="subtitle1">
                              No hay resultados
                            </Typography>
                            {search && (
                              <Typography variant="body2" align="center">
                                No se encontraron resultados para &nbsp;
                                <strong>&quot;{search}&quot;</strong>
                              </Typography>
                            )}
                          </Paper>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10]}
            getItemAriaLabel={(t) =>
              `Ir a la ${
                t === 'first'
                  ? 'primera'
                  : t === 'last'
                  ? 'última'
                  : t === 'next'
                  ? 'siguiente'
                  : 'anterior'
              } página`
            }
            component="div"
            count={total}
            rowsPerPage={10}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default AlarmLogs;
