import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  IconButton,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  TablePagination,
  Dialog,
  DialogContent,
  DialogTitle,
  Tooltip
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Page from 'src/components/Page';
import { useToasts } from 'src/hooks/useToasts';
import { createAlarm, getAlarm, updateAlarm } from 'src/services/AlarmServices';
import Iconify from 'src/components/Iconify';
import { UserListHead, UserListToolbar } from 'src/sections/appUser';
import Scrollbar from 'src/components/Scrollbar';
import { getUserData } from 'src/storage/userSessionStorage';
import { useConfirm } from 'material-ui-confirm';
import {
  createUser,
  getPdfByAlarm,
  getUsers,
  importUsers,
  logOutUser,
  updateUser
} from 'src/services/AppUserServices';
import { deleteUser, getPdf, getQr } from 'src/services/AppUserServices';
import { downloadFile, downloadURI } from 'src/utils/downloadFile';

// ----------------------------------------------------------------------

export default function AlarmForm() {
  const toast = useToasts();
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedUser, setSelectedUser] = useState(null);

  const alarmSchema = Yup.object({
    code: Yup.string().required('Campo requerido'),
    cost: Yup.number().required('Campo requerido').min(0, 'Debe ser un mayor o igual a 0')
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      cost: 0,
      budget: 0,
      lat: 0,
      lng: 0,
      message: '',
      description: '',
      mqttId: '',
      mac: '',
      phoneGroup: '',
      active: true,
      subscription: ''
    },
    validationSchema: alarmSchema,
    onSubmit: () => {
      alarmSubmit(values);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, values, getFieldProps, setFieldValue } =
    formik;

  const alarmSubmit = (form) => {
    if (id) {
      updateAlarm(id, form)
        .then((res) => {
          navigate('/alarm/list', { replace: true });
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          formik.setSubmitting(false);
        });
    } else
      createAlarm(form)
        .then((res) => {
          navigate('/alarm/list', { replace: true });
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          formik.setSubmitting(false);
        });
  };

  useEffect(() => {
    if (id) {
      getAlarm(id).then((x) => {
        Object.keys(x.data).map((y) => {
          setFieldValue(y, x.data[y]);
        });
      });
    }
  }, []);

  //users list
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  const { user } = getUserData();
  const role = user ? user.role : '';

  const confirm = useConfirm();

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'email', label: 'Correo', alignRight: false },
          { id: 'name', label: 'Nombre', alignRight: false },
          { id: 'lastName', label: 'Apellidos', alignRight: false },
          { id: 'phone', label: 'Teléfono', alignRight: false },
          ...(values.subscription === 'user'
            ? [
                { id: 'cost', label: 'Costo', alignRight: false },
                { id: 'budget', label: 'Presupuesto', alignRight: false }
              ]
            : []),
          { id: 'active', label: 'Activo', alignRight: false },
          { id: '' }
        ]
      : [
          { id: 'email', label: 'Correo', alignRight: false },
          { id: 'name', label: 'Nombre', alignRight: false },
          { id: 'lastName', label: 'Apellidos', alignRight: false },
          { id: 'phone', label: 'Teléfono', alignRight: false },
          { id: 'active', label: 'Activo', alignRight: false },
          { id: '' }
        ];

  useEffect(() => {
    setLoading(true);
    getUsers(page, pageLength, search, id)
      .then((x) => {
        setUsers(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page, pageLength]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (page > 1) {
        setPage(1);
      } else if (search != null) {
        setLoading(true);
        getUsers(page, 10, search, id)
          .then((x) => {
            setUsers(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [search]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangePageLength = (e) => {
    setPageLength(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    confirm({
      description: 'Se eliminará permanentemente este usuario',
      title: 'Está seguro'
    })
      .then(() => {
        deleteUser(id)
          .then(() => setUsers(users.filter((x) => x._id !== id)))
          .catch(() => {
            toast.error('No se pudo eliminar el usuario');
          });
      })
      .catch(() => {});
  };

  const handleGetQr = (user) => {
    getQr(user._id).then((r) => {
      const { qr, token } = r.data;
      confirm({
        description: (
          <Stack>
            <Stack spacing={3} justifyContent="center" alignItems={'center'} direction="row">
              <img width="250px" height="250px" src={qr} />
              <Tooltip title="Descargar" placement="top">
                <IconButton onClick={() => downloadURI(qr, `qr${user.name}`)}>
                  <Iconify icon="eva:cloud-download-outline" width={30} height={30} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copiar" placement="top">
                <IconButton
                  onClick={() => {
                    navigator.clipboard.writeText(token);
                  }}
                >
                  <Iconify icon="eva:clipboard-outline" width={30} height={30} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        ),
        title: 'Qr',
        allowClose: false,
        hideCancelButton: true
      });
    });
  };

  const handleGetPdf = () => {
    if (selected.length > 0) getPdf(selected).then((x) => downloadFile(x.data, 'users.pdf'));
    else {
      getPdfByAlarm(id).then((x) => downloadFile(x.data, 'users.pdf'));
    }
  };

  const handleLogOut = (id) => {
    confirm({
      description: 'Se cerrará la sesión de este usuario',
      title: 'Está seguro'
    })
      .then(() => {
        logOutUser(id).catch(() => {
          toast.error('No se pudo cerrarla sesión del usuario');
        });
      })
      .catch(() => {});
  };

  const emptyRows = page > 1 ? Math.max(0, 10 - users.length) : 0;

  const isUserNotFound = users.length === 0;

  //user form
  const userSchema = Yup.object({
    email: Yup.string().email('Correo debe ser un correo válido').required('Correo es requerido'),
    name: Yup.string().required('Nombre es requerido'),
    lastName: Yup.string(),
    phone: Yup.string().required('Teléfono requerido')
  });

  const defaultUser = {
    email: '',
    name: '',
    lastName: '',
    phone: '',
    address: '',
    observations: '',
    active: true,
    alarm: id
  };

  const userFormik = useFormik({
    initialValues: defaultUser,
    validationSchema: userSchema,
    onSubmit: () => {
      userSubmit(userValues);
    }
  });

  const {
    errors: userErrors,
    touched: userTouched,
    handleSubmit: userHandleSubmit,
    isSubmitting: userIsSubmitting,
    values: userValues,
    getFieldProps: userGetFieldProps,
    setFieldValue: userSetFieldValue
  } = userFormik;

  const userSubmit = (form) => {
    if (selectedUser._id) {
      updateUser(selectedUser._id, form)
        .then((res) => {
          setUsers((prev) => {
            const i = prev.findIndex((x) => x._id === selectedUser._id);
            const temp = [...prev];
            temp[i] = res;
            return temp;
          });
          setSelectedUser(null);
          userFormik.setSubmitting(false);
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          userFormik.setSubmitting(false);
        });
    } else
      createUser(form)
        .then((res) => {
          setUsers((prev) => [...prev, res]);
          setSelectedUser(null);
          userFormik.setSubmitting(false);
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          userFormik.setSubmitting(false);
        });
  };

  useEffect(() => {
    if (selectedUser) {
      Object.keys(selectedUser).forEach((x) => {
        if (x !== '_id') userSetFieldValue(x, selectedUser[x]);
      });
    }
  }, [selectedUser]);

  const handleImportClick = (f) => {
    importUsers(f, id)
      .then(() => {
        toast.success('Usuarios importados');
        setLoading(true);
        getUsers(page, 10, search, id)
          .then((x) => {
            setUsers(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      })
      .catch(() => {
        toast.error('Ha ocurrido un error');
      });
  };

  return (
    <Page title="Alarmas">
      <Container>
        <Stack sx={{ mb: 5 }} spacing={3} direction={'column'}>
          <Typography variant="h4" gutterBottom>
            {id ? 'Editar' : 'Crear'} alarma{' '}
            <FormControlLabel
              sx={{ ml: 5 }}
              control={<Checkbox checked={values.active} {...getFieldProps('active')} />}
              label="Activa"
            />
            {id && (
              <Button
                color="inherit"
                variant="contained"
                sx={{ bgcolor: 'primary.main', color: 'background.paper', ml: 10 }}
                onClick={() => navigate(`/alarm/logs/${id}`)}
              >
                Ver Histórico{' '}
              </Button>
            )}
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3} sx={{ mb: 5 }}>
              <Card>
                <Stack spacing={3} sx={{ p: 3 }}>
                  <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                    <TextField
                      fullWidth
                      label="Código"
                      {...getFieldProps('code')}
                      error={Boolean(touched.code && errors.code)}
                      helperText={touched.code && errors.code}
                      inputProps={{ readOnly: role !== 'admin' }}
                    />
                    {role === 'admin' && (
                      <>
                        <TextField
                          fullWidth
                          label="Costo"
                          type="number"
                          {...getFieldProps('cost')}
                          error={Boolean(touched.cost && errors.cost)}
                          helperText={touched.cost && errors.cost}
                        />
                        <TextField
                          fullWidth
                          label="Presupuesto"
                          type="number"
                          {...getFieldProps('budget')}
                          error={Boolean(touched.budget && errors.budget)}
                          helperText={touched.budget && errors.budget}
                        />
                      </>
                    )}
                  </Stack>
                  {role === 'admin' && (
                    <>
                      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                        <TextField
                          fullWidth
                          select
                          label="Tipo de suscripción"
                          {...getFieldProps('subscription')}
                          error={Boolean(touched.subscription && errors.subscription)}
                          helperText={touched.subscription && errors.subscription}
                        >
                          <MenuItem key={''} value={''}></MenuItem>
                          <MenuItem value={'user'} key="user">
                            Por Usuario
                          </MenuItem>
                          <MenuItem value={'alarm'} key="alarm">
                            Por Alarma
                          </MenuItem>
                        </TextField>
                        <TextField
                          fullWidth
                          label="Mqtt ID"
                          {...getFieldProps('mqttId')}
                          error={Boolean(touched.mqttId && errors.mqttId)}
                          helperText={touched.mqttId && errors.mqttId}
                        />
                        <TextField
                          fullWidth
                          label="Grupo"
                          {...getFieldProps('phoneGroup')}
                          error={Boolean(touched.phoneGroup && errors.phoneGroup)}
                          helperText={touched.phoneGroup && errors.phoneGroup}
                        />
                      </Stack>

                      <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                        <TextField
                          fullWidth
                          label="Mac"
                          {...getFieldProps('mac')}
                          error={Boolean(touched.mac && errors.mac)}
                          helperText={touched.mac && errors.mac}
                        />
                        <TextField
                          fullWidth
                          label="Latitud"
                          {...getFieldProps('lat')}
                          type="number"
                          error={Boolean(touched.lat && errors.lat)}
                          helperText={touched.lat && errors.lat}
                        />
                        <TextField
                          fullWidth
                          label="Longitud"
                          {...getFieldProps('lng')}
                          type="number"
                          error={Boolean(touched.lng && errors.lng)}
                          helperText={touched.lng && errors.lng}
                        />
                      </Stack>
                      <TextField
                        fullWidth
                        label="Mensaje"
                        {...getFieldProps('message')}
                        error={Boolean(touched.message && errors.message)}
                        helperText={touched.message && errors.message}
                      />
                      <TextField
                        fullWidth
                        label="Descripción"
                        {...getFieldProps('description')}
                        error={Boolean(touched.description && errors.description)}
                        helperText={touched.description && errors.description}
                        rows={3}
                        multiline
                      />
                    </>
                  )}
                </Stack>
              </Card>
              {role === 'admin' && (
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {id ? 'Editar' : 'Crear'}
                </LoadingButton>
              )}
              <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                  Usuarios
                </Typography>
                <Button
                  variant="contained"
                  onClick={() => setSelectedUser(defaultUser)}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Usuario
                </Button>
              </Stack>
              <Card>
                <UserListToolbar
                  numSelected={selected.length}
                  filter={search}
                  onFilter={handleSearch}
                  onGetPdf={handleGetPdf}
                  onImportUsers={handleImportClick}
                />
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        headLabel={TABLE_HEAD}
                        rowCount={total}
                        numSelected={selected.length}
                        onSelectAllClick={handleSelectAllClick}
                      />
                      <TableBody>
                        {users.map((row) => {
                          const { _id, email, name, lastName, phone, cost, budget, active } = row;
                          const isItemSelected = selected.indexOf(_id) !== -1;
                          return (
                            <TableRow
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, _id)}
                                />
                              </TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{lastName}</TableCell>
                              <TableCell align="left">{phone}</TableCell>
                              {role === 'admin' && values.subscription === 'user' && (
                                <>
                                  <TableCell align="left">{cost}</TableCell>
                                  <TableCell align="left">{budget}</TableCell>
                                </>
                              )}
                              <TableCell align="left">{active ? 'Si' : 'No'}</TableCell>
                              <TableCell align="right">
                                <Stack direction="row">
                                  <Tooltip title="Editar" placement="top">
                                    <IconButton onClick={() => setSelectedUser(row)}>
                                      <Iconify icon="eva:edit-fill" width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Ver Qr" placement="top">
                                    <IconButton
                                      onClick={() => {
                                        handleGetQr(row);
                                      }}
                                    >
                                      <Iconify
                                        icon={{
                                          body: `<g fill="currentColor"><path fill-rule="evenodd" d="M6 6v2h2V6H6ZM5 4.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5ZM12 6v2h2V6h-2Zm-1-1.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5h-4ZM6 12v2h2v-2H6Zm-1-1.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H5Z" clip-rule="evenodd"/><path d="M10.5 11a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-4Z"/><path fill-rule="evenodd" d="M2 3a1 1 0 0 1 1-1h3.5a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M3 2a1 1 0 0 1 1 1v3.5a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1Zm0 16a1 1 0 0 1-1-1v-3.5a1 1 0 1 1 2 0V17a1 1 0 0 1-1 1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M2 17a1 1 0 0 1 1-1h3.5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm16 0a1 1 0 0 1-1 1h-3.5a1 1 0 1 1 0-2H17a1 1 0 0 1 1 1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M17 18a1 1 0 0 1-1-1v-3.5a1 1 0 1 1 2 0V17a1 1 0 0 1-1 1Zm0-16a1 1 0 0 1 1 1v3.5a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M18 3a1 1 0 0 1-1 1h-3.5a1 1 0 1 1 0-2H17a1 1 0 0 1 1 1Z" clip-rule="evenodd"/></g>`
                                        }}
                                        width={24}
                                        height={24}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Eliminar" placement="top">
                                    <IconButton onClick={() => handleDelete(_id)}>
                                      <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="Cerrar Sesión" placement="top">
                                    <IconButton onClick={() => handleLogOut(_id)}>
                                      <Iconify icon="eva:log-out-outline" width={24} height={24} />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={TABLE_HEAD.length} />
                          </TableRow>
                        )}
                      </TableBody>
                      {isUserNotFound && (
                        <TableBody>
                          <TableRow>
                            <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                              <Paper>
                                <Typography gutterBottom align="center" variant="subtitle1">
                                  No hay resultados
                                </Typography>
                                {search && (
                                  <Typography variant="body2" align="center">
                                    No se encontraron resultados para &nbsp;
                                    <strong>&quot;{search}&quot;</strong>
                                  </Typography>
                                )}
                              </Paper>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>
                <TablePagination
                  rowsPerPageOptions={[10, 20, 50]}
                  labelRowsPerPage="Usuarios por página"
                  getItemAriaLabel={(t) =>
                    `Ir a la ${
                      t === 'first'
                        ? 'primera'
                        : t === 'last'
                        ? 'última'
                        : t === 'next'
                        ? 'siguiente'
                        : 'anterior'
                    } página`
                  }
                  component="div"
                  count={total}
                  rowsPerPage={pageLength}
                  page={page - 1}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangePageLength}
                />
              </Card>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
      <Dialog open={selectedUser} onClose={() => setSelectedUser(null)}>
        <DialogTitle>
          <Typography variant="h4" gutterBottom>
            {selectedUser?._id ? 'Editar' : 'Crear'} usuario
            <FormControlLabel
              sx={{ ml: 5 }}
              control={<Checkbox checked={userValues.active} {...userGetFieldProps('active')} />}
              label="Activo"
            />
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={userFormik}>
            <Form autoComplete="off" noValidate onSubmit={userHandleSubmit}>
              <Stack spacing={3} sx={{ pt: 2 }}>
                <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <TextField
                    fullWidth
                    autoComplete="username"
                    type="email"
                    label="Correo"
                    {...userGetFieldProps('email')}
                    error={Boolean(userTouched.email && userErrors.email)}
                    helperText={userTouched.email && userErrors.email}
                  />
                  {role === 'admin' && values.subscription === 'user' && (
                    <TextField
                      fullWidth
                      type="number"
                      label="Presupuesto"
                      {...userGetFieldProps('budget')}
                      error={Boolean(userTouched.budget && userErrors.budget)}
                      helperText={userTouched.budget && userErrors.budget}
                    />
                  )}
                </Stack>
                <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                  <TextField
                    fullWidth
                    label="Nombre"
                    {...userGetFieldProps('name')}
                    error={Boolean(userTouched.name && userErrors.name)}
                    helperText={userTouched.name && userErrors.name}
                  />
                  <TextField
                    fullWidth
                    label="Apellidos"
                    {...userGetFieldProps('lastName')}
                    error={Boolean(userTouched.lastName && userErrors.lastName)}
                    helperText={userTouched.lastName && userErrors.lastName}
                  />
                  <TextField
                    fullWidth
                    label="Teléfono"
                    {...userGetFieldProps('phone')}
                    error={Boolean(userTouched.phone && userErrors.phone)}
                    helperText={userTouched.phone && userErrors.phone}
                  />
                </Stack>
                <TextField
                  fullWidth
                  autoComplete="address"
                  type="text"
                  label="Dirección"
                  {...userGetFieldProps('address')}
                  error={Boolean(userTouched.address && userErrors.address)}
                  helperText={userTouched.address && userErrors.address}
                />
                <TextField
                  fullWidth
                  autoComplete="observations"
                  type="text"
                  label="Observaciones"
                  {...userGetFieldProps('observations')}
                  error={Boolean(userTouched.observations && userErrors.observations)}
                  helperText={userTouched.observations && userErrors.observations}
                  multiline
                  rows={4}
                />
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={userIsSubmitting}
                >
                  {selectedUser?._id ? 'Editar' : 'Crear'}
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </DialogContent>
      </Dialog>
    </Page>
  );
}
