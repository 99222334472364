import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getUsers(page, length, search, alarm) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/appUser?page=${page ?? ''}&length=${length ?? ''}&search=${search ?? ''}&alarm=${alarm ?? ''}`
  );
}

async function getUser(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(`/appUser/${id}`);
}

async function getQr(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(`/appUser/getQr/${id}`);
}

async function getPdf(ids) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      Authorization: `Bearer ${userData.token}`
    },
    responseType: 'arraybuffer'
  });

  return await axiosClient.post(`/appUser/pdf`, ids);
}

async function getPdfByAlarm(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/pdf',
      Authorization: `Bearer ${userData.token}`
    },
    responseType: 'arraybuffer'
  });

  return await axiosClient.post(`/appUser/pdfByAlarm/${id}`);
}

async function createUser(data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.post('/appUser', { ...data });
  return result.data;
}

async function updateUser(id, data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.put(`/appUser/${id}`, { ...data });
  return result.data;
}

async function deleteUser(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.delete(`/appUser/${id}`);
  return result.data;
}

async function logOutUser(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.post(`/appUser/logout/${id}`);
  return result.data;
}

async function importUsers(file, id) {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const formData = new FormData();
  formData.append('file', file);

  const result = await axiosClient.post(`/appUser/import/${id}`, formData);
  return result.data;
}

export {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
  getUser,
  getQr,
  getPdf,
  getPdfByAlarm,
  importUsers,
  logOutUser
};
