import { useNavigate } from 'react-router-dom';
// material
import { Button, Typography } from '@mui/material';
// components
import { getUserData, clearUserData } from '../../storage/userSessionStorage';

// ----------------------------------------------------------------------

export default function Account() {
  const navigate = useNavigate();
  const userData = getUserData();

  return (
    <>
      <Typography sx={{ color: 'primary.main' }}> {userData.user?.username}</Typography>
      <Button
        color="inherit"
        variant="contained"
        onClick={() => {
          clearUserData();
          navigate('/');
        }}
        sx={{ bgcolor: 'primary.main', color: 'background.paper' }}
      >
        Cerrar Sesión
      </Button>
    </>
  );
}
