import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';

// material
import { styled } from '@mui/material/styles';
import { Stack, Container, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import { useToasts } from 'src/hooks/useToasts';
import { changePasswordByCode } from 'src/services/AuthServices';
import { getUserByCode } from 'src/services/UserServices';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

export default function ConfirmUser() {
  const { code } = useParams();
  const toast = useToasts();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [validCode, setValidCode] = useState(false);

  useEffect(() => {
    getUserByCode(code).then((res) => setValidCode(true));
  }, []);

  const PasswordSchema = Yup.object({
    newPassword: Yup.string().required('Contraseña requerida'),
    confirmPass: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Las contraseñas no coinciden')
      .required('Contraseña requerida')
  });

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmPass: ''
    },
    validationSchema: PasswordSchema,
    onSubmit: () => {
      passwordSubmit(values);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, values, getFieldProps } = formik;

  const passwordSubmit = (form) => {
    changePasswordByCode({
      token: code,
      newPassword: form.newPassword
    })
      .then((res) => {
        navigate('/', { replace: true });
      })
      .catch((res) => {
        toast.error(`Ha ocurrido un error: ${res.response.data.message}`);
        formik.setSubmitting(false);
      });
  };

  return (
    validCode && (
      <RootStyle title="Cambiar contraseña">
        <Container maxWidth="sm">
          <ContentStyle>
            <Stack sx={{ mb: 5 }}>
              <Typography variant="h4" gutterBottom>
                Cambiar contraseña
              </Typography>
            </Stack>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    fullWidth
                    type={showNewPassword ? 'text' : 'password'}
                    label="Nueva Contraseña"
                    {...getFieldProps('newPassword')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowNewPassword((prev) => !prev)}
                          >
                            <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.newPassword && errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                  />
                  <TextField
                    fullWidth
                    type={showConfirmPassword ? 'text' : 'password'}
                    label="Confirmar Contraseña"
                    {...getFieldProps('confirmPass')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            edge="end"
                            onClick={() => setShowConfirmPassword((prev) => !prev)}
                          >
                            <Iconify
                              icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(touched.confirmPass && errors.confirmPass)}
                    helperText={touched.confirmPass && errors.confirmPass}
                  />
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Cambiar
                  </LoadingButton>
                </Stack>
              </Form>
            </FormikProvider>
          </ContentStyle>
        </Container>
      </RootStyle>
    )
  );
}
