import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import * as turf from '@turf/turf';
import { useEffect, useState } from 'react';
import marker from '../../img/marker.svg';
import marker2 from '../../img/marker2.svg';

export default function Map({ data, onClickAlarm }) {
  const [center, setCenter] = useState([0, 0]);

  useEffect(() => {
    const points = data.map((x) => {
      return [x.lat, x.lng];
    });
    if (points.length) {
      const features = turf.points(points);
      const center = turf.center(features);
      setCenter(center.geometry.coordinates);
    }
  }, [data]);

  const myIcon = new L.Icon({
    iconUrl: marker,
    iconRetinaUrl: marker,
    popupAnchor: [-0, -0],
    iconSize: [32, 32]
  });

  const iconLive = new L.Icon({
    iconUrl: marker2,
    iconRetinaUrl: marker2,
    popupAnchor: [-0, -0],
    iconSize: [32, 32]
  });

  return (
    <MapContainer
      center={center}
      zoom={14}
      scrollWheelZoom={false}
      style={{ height: '500px', width: '100%' }}
    >
      <ChangeView center={center} zoom={14} />
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {data.map((x, i) => (
        <Marker
          key={i}
          position={[x.lat, x.lng]}
          icon={x.live ? iconLive : myIcon}
          eventHandlers={{
            click: (e) => {
              onClickAlarm(x);
            },
            mouseover: (event) => event.target.openPopup()
          }}
        >
          <Popup>{x.code}</Popup>
        </Marker>
      ))}
    </MapContainer>
  );

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }
}
