const sidebarConfig = [
  {
    title: 'Inicio',
    path: '/dashboard/app',
    icon: 'eva:pie-chart-2-fill',
    roles: ['admin', 'alarmAdmin']
  },
  {
    title: 'Alarmas',
    icon: 'eva:bell-fill',
    path: '/alarm/list',
    roles: ['admin', 'alarmAdmin']
  },
  {
    title: 'Usuarios',
    icon: 'eva:people-fill',
    path: '/user/list',
    roles: ['admin']
  }
];

export default sidebarConfig;
