import axios from 'axios';
import { getUserData } from '../storage/userSessionStorage';

async function getAlarms(page, length, search) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/alarm?page=${page ?? ''}&length=${length ?? ''}&search=${search ?? ''}`
  );
}

async function getAlarm(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(`/alarm/${id}`);
}

async function createAlarm(data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.post('/alarm', { ...data });
  return result.data;
}

async function updateAlarm(id, data) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.put(`/alarm/${id}`, { ...data });
  return result.data;
}

async function deleteAlarm(id) {
  const userData = getUserData();

  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  const result = await axiosClient.delete(`/alarm/${id}`);
  return result.data;
}

async function getAlarmsLogs(page, length, search, alarm) {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(
    `/log?page=${page ?? ''}&length=${length ?? ''}&search=${search ?? ''}&alarm=${alarm ?? ''}`
  );
}

async function countLogs() {
  const userData = getUserData();
  const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userData.token}`
    }
  });

  return await axiosClient.get(`/log/count`);
}

export { getAlarms, createAlarm, updateAlarm, deleteAlarm, getAlarm, getAlarmsLogs, countLogs };
