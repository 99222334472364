import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useFormik, Form, FormikProvider, FieldArray, getIn } from 'formik';
// material
import { Stack, Container, Typography, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Page from 'src/components/Page';
import { createUser, getUser, updateUser } from 'src/services/AppUserServices';
import { useToasts } from 'src/hooks/useToasts';

// ----------------------------------------------------------------------

export default function UserForm() {
  const toast = useToasts();
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const alarm = searchParams.get('alarm');
  const RegisterSchema = Yup.object({
    email: Yup.string().email('Correo debe ser un correo válido').required('Correo es requerido'),
    name: Yup.string().required('Nombre es requerido'),
    lastName: Yup.string(),
    phone: Yup.string().required('Teléfono requerido')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      name: '',
      lastName: '',
      phone: '',
      alarm
    },
    validationSchema: RegisterSchema,
    onSubmit: () => {
      registerSubmit(values);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, values, getFieldProps, setFieldValue } =
    formik;

  const registerSubmit = (form) => {
    if (id) {
      updateUser(id, form)
        .then((res) => {
          navigate(-1, { replace: true });
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          formik.setSubmitting(false);
        });
    } else
      createUser(form)
        .then((res) => {
          navigate(-1, { replace: true });
        })
        .catch((res) => {
          toast.error(`${res.response.data.message}`);
          formik.setSubmitting(false);
        });
  };

  useEffect(() => {
    if (id) {
      getUser(id).then((x) => {
        Object.keys(x.data).map((y) => {
          setFieldValue(y, x.data[y]);
        });
      });
    }
  }, []);

  return (
    <Page title="Usuarios">
      <Container>
        <Stack sx={{ mb: 5 }}>
          <Typography variant="h4" gutterBottom>
            {id ? 'Editar' : 'Crear'} usuario
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <TextField
                fullWidth
                autoComplete="username"
                type="email"
                label="Correo"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <Stack spacing={3} direction={{ xs: 'column', sm: 'row' }}>
                <TextField
                  fullWidth
                  label="Nombre"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  fullWidth
                  label="Apellidos"
                  {...getFieldProps('lastName')}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
                <TextField
                  fullWidth
                  label="Teléfono"
                  {...getFieldProps('phone')}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
              </Stack>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                {id ? 'Editar' : 'Crear'}
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
