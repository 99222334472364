import {
  Container,
  Button,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  Typography,
  Card,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import Page from 'src/components/Page';
import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { deleteUser, getPdf, getQr, getUsers } from 'src/services/AppUserServices';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead, UserListToolbar } from 'src/sections/appUser';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import { getUserData } from 'src/storage/userSessionStorage';
import { downloadFile, downloadURI } from 'src/utils/downloadFile';

function AppUserList() {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [pageLength, setPageLength] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [searchParams] = useSearchParams();
  const alarm = searchParams.get('alarm');
  const { user } = getUserData();
  const role = user ? user.role : '';

  const confirm = useConfirm();

  const TABLE_HEAD =
    role === 'admin'
      ? [
          { id: 'email', label: 'Correo', alignRight: false },
          { id: 'name', label: 'Nombre', alignRight: false },
          { id: 'lastName', label: 'Apellidos', alignRight: false },
          { id: 'phone', label: 'Teléfono', alignRight: false },
          { id: 'cost', label: 'Costo', alignRight: false },
          { id: 'budget', label: 'Presupuesto', alignRight: false },
          { id: 'active', label: 'Activo', alignRight: false },
          { id: '' }
        ]
      : [
          { id: 'email', label: 'Correo', alignRight: false },
          { id: 'name', label: 'Nombre', alignRight: false },
          { id: 'lastName', label: 'Apellidos', alignRight: false },
          { id: 'phone', label: 'Teléfono', alignRight: false },
          { id: 'active', label: 'Activo', alignRight: false },
          { id: '' }
        ];

  useEffect(() => {
    setLoading(true);
    getUsers(page, pageLength, search, alarm)
      .then((x) => {
        setUsers(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page, pageLength]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (page > 1) {
        setPage(1);
      } else if (search != null) {
        setLoading(true);
        getUsers(page, 10, search, alarm)
          .then((x) => {
            setUsers(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [search]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangePageLength = (e) => {
    setPageLength(e.target.value);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    confirm({
      description: 'Se eliminará permanentemente este usuario',
      title: 'Está seguro'
    })
      .then(() => {
        deleteUser(id)
          .then(() => setUsers(users.filter((x) => x._id !== id)))
          .catch(() => {
            toast.error('No se pudo eliminar el usuario');
          });
      })
      .catch(() => {});
  };

  const handleGetQr = (user) => {
    getQr(user._id).then((r) => {
      const qr = r.data;
      confirm({
        description: (
          <Stack>
            <Stack spacing={3} justifyContent="center" alignItems={'center'} direction="row">
              <img width="250px" height="250px" src={qr} />
              <IconButton onClick={() => downloadURI(qr, `qr${user.name}`)}>
                <Iconify icon="eva:cloud-download-outline" width={30} height={30} />
              </IconButton>
            </Stack>
          </Stack>
        ),
        title: 'Qr',
        allowClose: false,
        hideCancelButton: true
      });
    });
  };

  const handleGetPdf = () => {
    getPdf(selected).then((x) => downloadFile(x.data, 'users.pdf'));
  };

  const emptyRows = page > 1 ? Math.max(0, 10 - users.length) : 0;

  const isUserNotFound = users.length === 0;

  return (
    <Page title="Usuarios">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to={`/appUser/add?alarm=${alarm}`}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nuevo Usuario
          </Button>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filter={search}
            onFilter={handleSearch}
            onGetPdf={handleGetPdf}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, email, name, lastName, phone, cost, budget, active } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{lastName}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        {role === 'admin' && (
                          <>
                            <TableCell align="left">{cost}</TableCell>
                            <TableCell align="left">{budget}</TableCell>
                          </>
                        )}
                        <TableCell align="left">{active ? 'Si' : 'No'}</TableCell>
                        <TableCell align="right">
                          <Stack direction="row">
                            <Tooltip title="Editar" placement="top">
                              <Link to={`/appUser/edit/${_id}`}>
                                <IconButton>
                                  <Iconify icon="eva:edit-fill" width={24} height={24} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Ver QR" placement="top">
                              <IconButton
                                onClick={() => {
                                  handleGetQr(row);
                                }}
                              >
                                <Iconify
                                  icon={{
                                    body: `<g fill="currentColor"><path fill-rule="evenodd" d="M6 6v2h2V6H6ZM5 4.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5ZM12 6v2h2V6h-2Zm-1-1.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5h-4ZM6 12v2h2v-2H6Zm-1-1.5a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5H5Z" clip-rule="evenodd"/><path d="M10.5 11a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5v-4Z"/><path fill-rule="evenodd" d="M2 3a1 1 0 0 1 1-1h3.5a1 1 0 0 1 0 2H3a1 1 0 0 1-1-1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M3 2a1 1 0 0 1 1 1v3.5a1 1 0 0 1-2 0V3a1 1 0 0 1 1-1Zm0 16a1 1 0 0 1-1-1v-3.5a1 1 0 1 1 2 0V17a1 1 0 0 1-1 1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M2 17a1 1 0 0 1 1-1h3.5a1 1 0 1 1 0 2H3a1 1 0 0 1-1-1Zm16 0a1 1 0 0 1-1 1h-3.5a1 1 0 1 1 0-2H17a1 1 0 0 1 1 1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M17 18a1 1 0 0 1-1-1v-3.5a1 1 0 1 1 2 0V17a1 1 0 0 1-1 1Zm0-16a1 1 0 0 1 1 1v3.5a1 1 0 1 1-2 0V3a1 1 0 0 1 1-1Z" clip-rule="evenodd"/><path fill-rule="evenodd" d="M18 3a1 1 0 0 1-1 1h-3.5a1 1 0 1 1 0-2H17a1 1 0 0 1 1 1Z" clip-rule="evenodd"/></g>`
                                  }}
                                  width={24}
                                  height={24}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar" placement="top">
                              <IconButton onClick={() => handleDelete(_id)}>
                                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={TABLE_HEAD.length} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={TABLE_HEAD.length} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            No hay resultados
                          </Typography>
                          {search && (
                            <Typography variant="body2" align="center">
                              No se encontraron resultados para &nbsp;
                              <strong>&quot;{search}&quot;</strong>
                            </Typography>
                          )}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            labelRowsPerPage="Usuarios por página"
            getItemAriaLabel={(t) =>
              `Ir a la ${
                t === 'first'
                  ? 'primera'
                  : t === 'last'
                  ? 'última'
                  : t === 'next'
                  ? 'siguiente'
                  : 'anterior'
              } página`
            }
            component="div"
            count={total}
            rowsPerPage={pageLength}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangePageLength}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default AppUserList;
