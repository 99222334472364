import {
  Container,
  Button,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  useTheme,
  Typography,
  Card,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';
import Page from 'src/components/Page';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { deleteAlarm, getAlarms } from 'src/services/AlarmServices';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { AlarmListHead, AlarmListToolbar, AlarmMoreMenu } from 'src/sections/alarm';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import { getUserData } from 'src/storage/userSessionStorage';

const TABLE_HEAD = [
  { id: 'code', label: 'Código', alignRight: false },
  { id: 'cost', label: 'Costo', alignRight: false },
  { id: 'subscription', label: 'Suscripción', alignRight: false },
  { id: 'budget', label: 'Presupuesto', alignRight: false },
  { id: 'active', label: 'Activa', alignRight: false },
  { id: '' }
];

const subscriptionType = {
  user: 'Por Usuario',
  alarm: 'Por Alarma'
};

function AlarmList() {
  const [alarms, setAlarms] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');

  const confirm = useConfirm();

  const { user } = getUserData();
  const role = user ? user.role : '';

  useEffect(() => {
    setLoading(true);
    getAlarms(page, 10, search)
      .then((x) => {
        setAlarms(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (page > 1) {
        setPage(1);
      } else if (search != null) {
        setLoading(true);
        getAlarms(page, 10, search)
          .then((x) => {
            setAlarms(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [search]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = alarms.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleDelete = (id) => {
    confirm({
      description: 'Se eliminará permanentemente esta alarma',
      title: 'Está seguro'
    }).then(() => {
      deleteAlarm(id)
        .then(() => setAlarms(alarms.filter((x) => x._id !== id)))
        .catch(() => {
          toast.error('No se pudo eliminar la alarma');
        });
    });
  };

  const emptyRows = page > 1 ? Math.max(0, 10 - alarms.length) : 0;

  const isUserNotFound = alarms.length === 0;

  return (
    <Page title="Alarmas">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Alarmas
          </Typography>
          {role === 'admin' && (
            <Button
              variant="contained"
              component={Link}
              to="/alarm/add"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Nueva Alarma
            </Button>
          )}
        </Stack>
        <Card>
          <AlarmListToolbar numSelected={selected.length} filter={search} onFilter={handleSearch} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <AlarmListHead
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {alarms.map((row) => {
                    const { _id, code, cost, subscription, budget, active } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        {role === 'admin' && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, _id)}
                            />
                          </TableCell>
                        )}
                        <TableCell align="left">{code}</TableCell>
                        <TableCell align="left">{cost}</TableCell>
                        <TableCell align="left">{subscriptionType[subscription]}</TableCell>
                        <TableCell align="left">{budget}</TableCell>
                        <TableCell align="left">{active ? 'Si' : 'No'}</TableCell>
                        <TableCell align="right">
                          <Stack direction="row">
                            <Tooltip title="Editar" placement="top">
                              <Link to={`/alarm/edit/${_id}`}>
                                <IconButton>
                                  <Iconify icon="eva:edit-fill" width={24} height={24} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            {role === 'admin' && (
                              <Tooltip title="Eliminar" placement="top">
                                <IconButton onClick={() => handleDelete(_id)}>
                                  <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip title="Histórico" placement="top">
                              <Link to={`/alarm/logs/${_id}`}>
                                <IconButton>
                                  <Iconify icon="eva:clock-outline" width={24} height={24} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={role === 'admin' ? 6 : 5} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={role === 'admin' ? 6 : 5} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            No hay resultados
                          </Typography>
                          {search && (
                            <Typography variant="body2" align="center">
                              No se encontraron resultados para &nbsp;
                              <strong>&quot;{search}&quot;</strong>
                            </Typography>
                          )}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10]}
            getItemAriaLabel={(t) =>
              `Ir a la ${
                t === 'first'
                  ? 'primera'
                  : t === 'last'
                  ? 'última'
                  : t === 'next'
                  ? 'siguiente'
                  : 'anterior'
              } página`
            }
            component="div"
            count={total}
            rowsPerPage={10}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default AlarmList;
