import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

//
import DashboardApp from './pages/DashboardApp';
import NotFound from './pages/Page404';
import Login from './pages/Login';
import { getUserData } from './storage/userSessionStorage';
import Users from './pages/User/UserList';
import UserForm from './pages/User/UserForm';
import AlarmList from './pages/Alarm/AlarmList';
import AlarmForm from './pages/Alarm/AlarmForm';
import ConfirmUser from './pages/Confirm/ConfirmUser';
import AppUsers from './pages/AppUser/AppUserList';
import AppUserForm from './pages/AppUser/AppUserForm';
import AlarmLogs from './pages/Alarm/AlarmLogs';

// ----------------------------------------------------------------------

function ProtectedRoute({ children }) {
  const { token } = getUserData();
  const notAllowed = !token;

  if (notAllowed) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default function Router() {
  const { user } = getUserData();
  const role = user ? user.role : '';

  return useRoutes(
    role === 'admin'
      ? [
          {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
              {
                path: 'app',
                element: (
                  <ProtectedRoute>
                    <DashboardApp />
                  </ProtectedRoute>
                )
              }
            ]
          },
          {
            path: '/user',
            element: <DashboardLayout />,
            children: [
              {
                path: 'list',
                element: (
                  <ProtectedRoute>
                    <Users />
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <UserForm />
                  </ProtectedRoute>
                )
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute>
                        <UserForm />
                      </ProtectedRoute>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: '/appUser',
            element: <DashboardLayout />,
            children: [
              {
                path: 'list',
                element: (
                  <ProtectedRoute>
                    <AppUsers />
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <AppUserForm />
                  </ProtectedRoute>
                )
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute>
                        <AppUserForm />
                      </ProtectedRoute>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: '/alarm',
            element: <DashboardLayout />,
            children: [
              {
                path: 'list',
                element: (
                  <ProtectedRoute>
                    <AlarmList />
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <AlarmForm />
                  </ProtectedRoute>
                )
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute>
                        <AlarmForm />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: 'logs',
                children: [
                  {
                    path: ':alarm',
                    element: (
                      <ProtectedRoute>
                        <AlarmLogs />
                      </ProtectedRoute>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: '/', element: <Navigate to="/dashboard/app" /> },
              { path: 'login', element: <Login /> },
              {
                path: 'confirm',
                element: <LogoOnlyLayout />,
                children: [
                  {
                    path: ':code',
                    element: <ConfirmUser />
                  }
                ]
              },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" /> }
            ]
          },
          { path: '*', element: <Navigate to="/404" replace /> }
        ]
      : [
          {
            path: '/dashboard',
            element: <DashboardLayout />,
            children: [
              {
                path: 'app',
                element: (
                  <ProtectedRoute>
                    <DashboardApp />
                  </ProtectedRoute>
                )
              }
            ]
          },
          {
            path: '/alarm',
            element: <DashboardLayout />,
            children: [
              {
                path: 'list',
                element: (
                  <ProtectedRoute>
                    <AlarmList />
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <AlarmForm />
                  </ProtectedRoute>
                )
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute>
                        <AlarmForm />
                      </ProtectedRoute>
                    )
                  }
                ]
              },
              {
                path: 'logs',
                children: [
                  {
                    path: ':alarm',
                    element: (
                      <ProtectedRoute>
                        <AlarmLogs />
                      </ProtectedRoute>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: '/appUser',
            element: <DashboardLayout />,
            children: [
              {
                path: 'list',
                element: (
                  <ProtectedRoute>
                    <AppUsers />
                  </ProtectedRoute>
                )
              },
              {
                path: 'add',
                element: (
                  <ProtectedRoute>
                    <AppUserForm />
                  </ProtectedRoute>
                )
              },
              {
                path: 'edit',
                children: [
                  {
                    path: ':id',
                    element: (
                      <ProtectedRoute>
                        <AppUserForm />
                      </ProtectedRoute>
                    )
                  }
                ]
              }
            ]
          },
          {
            path: '/',
            element: <LogoOnlyLayout />,
            children: [
              { path: '/', element: <Navigate to="/dashboard/app" /> },
              { path: 'login', element: <Login /> },
              {
                path: 'confirm',
                element: <LogoOnlyLayout />,
                children: [
                  {
                    path: ':code',
                    element: <ConfirmUser />
                  }
                ]
              },
              { path: '404', element: <NotFound /> },
              { path: '*', element: <Navigate to="/404" /> }
            ]
          },
          { path: '*', element: <Navigate to="/404" replace /> }
        ]
  );
}
