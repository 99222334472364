import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment
} from '@mui/material';
// component
import Iconify from '../../components/Iconify';
import { getUserData } from 'src/storage/userSessionStorage';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

LogListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  onDelete: PropTypes.func
};

export default function LogListToolbar({ filter, onFilter }) {
  const { user } = getUserData();
  const role = user ? user.role : '';

  return (
    <RootStyle>
      <SearchStyle
        value={filter}
        onChange={onFilter}
        placeholder="Buscar"
        startAdornment={
          <InputAdornment position="start">
            <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
