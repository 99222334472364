import {
  Container,
  Button,
  Stack,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  TablePagination,
  useTheme,
  Typography,
  Card,
  Paper,
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip
} from '@mui/material';
import Page from 'src/components/Page';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { createUserCode, deleteUser, getUsers } from 'src/services/UserServices';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { UserListHead, UserListToolbar, UserMoreMenu } from 'src/sections/user';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
// import { getTicketData } from 'src/storage/ticketLocalStorage';

const TABLE_HEAD = [
  { id: 'email', label: 'Correo', alignRight: false },
  { id: 'name', label: 'Nombre', alignRight: false },
  { id: 'lastName', label: 'Apellidos', alignRight: false },
  { id: 'phone', label: 'Teléfono', alignRight: false },
  { id: 'alarms', label: 'Alarmas', alignRight: false },
  { id: '' }
];

function UserList() {
  const [users, setUsers] = useState([]);
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const confirm = useConfirm();

  const theme = useTheme();

  useEffect(() => {
    setLoading(true);
    getUsers(page, 10, search)
      .then((x) => {
        setUsers(x.data ? x.data.result : []);
        setTotal(x.data ? x.data.count : 0);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [page]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (page > 1) {
        setPage(1);
      } else if (search != null) {
        setLoading(true);
        getUsers(page, 10, search)
          .then((x) => {
            setUsers(x.data ? x.data.result : []);
            setTotal(x.data ? x.data.count : 0);
            setLoading(false);
          })
          .catch(() => setLoading(false));
      }
    }, 500);

    return () => clearTimeout(getData);
  }, [search]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleGenerateLink = (user) => {
    const origin = window.location.origin;
    createUserCode(user._id).then((r) => {
      confirm({
        description: (
          <Stack>
            El link del usuario {user.name} es:
            <Stack spacing={3} direction="row" sx={{ width: '100%' }}>
              <Link
                style={{
                  width: '90%',
                  overflowWrap: 'break-word'
                }}
                to={`${origin}/confirm/${r.data}`}
                target="_blank"
              >
                {origin}/confirm/{r.data}
              </Link>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(`${origin}/confirm/${r.data}`);
                }}
                color="inherit"
              >
                <Iconify icon="eva:clipboard-outline" />
              </IconButton>
            </Stack>
          </Stack>
        ),
        hideCancelButton: true,
        allowClose: false,
        title: 'Link'
      });
    });
  };

  const handleDelete = (user) => {
    confirm({
      description: `Se eliminará permanentemente el usuario ${user.name}`,
      allowClose: false,
      title: 'Está seguro'
    })
      .then(() => {
        deleteUser(user._id)
          .then(() => setUsers(users.filter((x) => x._id !== user._id)))
          .catch(() => {
            toast.error('No se pudo eliminar el usuario');
          });
      })
      .catch(() => {
        console.log('rejected');
      });
  };

  const emptyRows = page > 1 ? Math.max(0, 10 - users.length) : 0;

  const isUserNotFound = users.length === 0;

  return (
    <Page title="Usuarios">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Usuarios
          </Typography>
          <Button
            variant="contained"
            component={Link}
            to="/user/add"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nuevo Usuario
          </Button>
        </Stack>
        <Card>
          <UserListToolbar numSelected={selected.length} filter={search} onFilter={handleSearch} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {users.map((row) => {
                    const { _id, email, name, lastName, phone, alarms } = row;
                    const isItemSelected = selected.indexOf(_id) !== -1;
                    return (
                      <TableRow
                        key={_id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            onChange={(event) => handleClick(event, _id)}
                          />
                        </TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{name}</TableCell>
                        <TableCell align="left">{lastName}</TableCell>
                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{alarms?.length}</TableCell>
                        <TableCell align="right">
                          <Stack direction="row">
                            <Tooltip title="Editar" placement="top">
                              <Link to={`/user/edit/${_id}`}>
                                <IconButton>
                                  <Iconify icon="eva:edit-fill" width={24} height={24} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                            <Tooltip title="Ver Enlace" placement="top">
                              <IconButton onClick={() => handleGenerateLink(row)}>
                                <Iconify icon="eva:link-outline" width={24} height={24} />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar" placement="top">
                              <IconButton onClick={() => handleDelete(row)}>
                                <Iconify icon="eva:trash-2-outline" width={24} height={24} />
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={3} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper>
                          <Typography gutterBottom align="center" variant="subtitle1">
                            No hay resultados
                          </Typography>
                          {search && (
                            <Typography variant="body2" align="center">
                              No se encontraron resultados para &nbsp;
                              <strong>&quot;{search}&quot;</strong>
                            </Typography>
                          )}
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={total}
            rowsPerPage={10}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        </Card>
      </Container>
    </Page>
  );
}

export default UserList;
